import React,{useState, useEffect, useRef} from 'react'
import LandingHeader from '../header'
import PlanningPokerSvg from '../../assets/images/planning_poker_card_image'
import checksvg from '../../assets/images/check.svg'
import cardsmac from '../../assets/images/cardsmac.jpg'
import cardsall from '../../assets/images/cardsall.jpg'
import cardspack2 from '../../assets/images/cardspack2.jpg'
import './style.scss'
import FreePokerCardsForm from './poker-cards form'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
const FreePlanningPokerCards = (props) =>{
  
    const [top, setTop] = useState(0)
    const [counter, setCounter] = useState(null)
    const wr = useRef(null)
    const wr1 = useRef(null)
    useEffect(()=>{
      
        if(counter === null && props.data.allMarkdownRemark && props.data.allMarkdownRemark.edges.length !==0){
            const dt = props.data.allMarkdownRemark.edges.filter(elt=>elt.node.frontmatter.title === "Free Planing Poker Cards")
            console.log(dt,'props.data.allMarkdownRemark',props.data)
            setCounter(dt.length !==0 ? dt[0].node.frontmatter.counter : null)
        }
     
        if(window !== undefined && window.screen.width >= 768){
            window.addEventListener('scroll',watchScroll)
        return () =>{
            window.removeEventListener('scroll',watchScroll)
        }}
        },[top,props,counter])
       
        let olds = []
        const watchScroll=(e)=>{      
            let d = wr.current
            let d1 = wr1.current
            olds.push(window.scrollY);
    
            if(olds.length > 0){
            if(olds[0] < window.scrollY){
            if(d.offsetTop - 80 < window.scrollY){
                if(top === 0){
                    setTop(80)
                }
            }
            }else{
                if(d1.offsetTop - 80 > window.scrollY){
                   
                    setTop(0)
                   
                }
            }
            }
           
    
    
        }
return(
    <div className="body-container-wrapper free-planning-poker-cards">
        <Helmet defer={false}>
            <title>
            Free Planning Poker Cards | JDSolutions
            </title>
            <meta property="og:title" content={`Free Planning Poker Cards | JDSolutions`} />
                <meta property="og:type" content="page" />
                {/* <meta property="og:image" content={bigbanner} />  */}
        </Helmet>
        <div className="body-container container-fluid">
        <LandingHeader fixed={true}/>

        <div className="row-fluid-wrapper row-depth-1 row-number-5 " >
        <div className="row-fluid " >
        <div className="span12 widget-span widget-type-cell main"  >

        <div className="row-fluid-wrapper row-depth-1 row-number-6 " >
        <div className="row-fluid " >
        <div className="container" >
        <div className="span12 widget-span widget-type-cell "  >

        <div className="row-fluid-wrapper row-depth-1 row-number-7 " >
        <div className="row-fluid " >
        <div className="span12 widget-span widget-type-cell sec-wrapper"  >

        <div className="row-fluid-wrapper row-depth-1 row-number-8 " >
        <div className="row-fluid " >
        <div className="span12 widget-span widget-type-cell sec-inner"  >

        <div className="row-fluid-wrapper row-depth-2 row-number-1 " >
        <div className="row-fluid " >
        <div className="span8 widget-span widget-type-cell left-cont">

        <div className="theiaStickySidebar">
        <div className="row-fluid-wrapper row-depth-2 row-number-2 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell sec-1" >

        <div className="row-fluid-wrapper row-depth-2 row-number-3 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-rich_text " >
        <div className="cell-wrapper layout-widget-wrapper">
        <span id="hs_cos_wrapper_module_1540536096606218" 
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
        <div className="en-text">
        <h2>Ready to estimate your project&nbsp;in a fun &amp; accurate way by using Planning Poker Cards ?</h2>
        <p>Would you prefer to spend time on estimations or have fun… or both?</p>
        <p>How to make a software development team enjoy their daily routine? Make them play poker… but by using planning poker cards it is not only fun but also useful when it is time to work on your project time estimations.</p>
        </div>
       </span>
        </div>
        </div>
        </div>
        </div>

        <div className="row-fluid-wrapper row-depth-2 row-number-4 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_html svg-image">
        <div className="cell-wrapper layout-widget-wrapper">
        <span>
        <PlanningPokerSvg/>
        </span>
        </div>
        </div>
        </div>
        </div>

        </div>
        </div>
        </div>
        <div className="row-fluid-wrapper row-depth-2 row-number-5 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-rich_text sec-2">
        <div className="cell-wrapper layout-widget-wrapper">
        <span id="hs_cos_wrapper_module_1540536195870226" 
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
        <div className="en-text">
        <h5>What are the benefits of this?&nbsp;</h5>
        <ul>
        <li>
            <img src={checksvg} alt="check svg" width="32" height="32"/>
        <p>Some software development teams measure via ‘story points’ where they know the size of one story point, other dev teams measure in days and some developers estimate in ideal hours. It actually doesn't matter what the number represents - as long as the whole team uses the same scale.</p>
        </li>
        <li>
            <img src={checksvg} alt="check svg" width="32" height="32"/>
        <p>It is teamwork and everybody on the table has an equal voice. You'll be surprised that every software developer on the table has an equal input and vision when it comes to estimations.</p>
        </li>
        <li>
            <img src={checksvg} alt="check svg" width="32" height="32"/>
        <p>The pressure to not have the minimum or maximum value really makes each developer on the table think twice before giving an actual card. This will result in better estimations.</p>
        </li>
        <li>
            <img src={checksvg} alt="check svg" width="32" height="32"/>
        <p>The feeling that the whole development team was part of the planning gives us the nice outcome that they are more committed to&nbsp;sticking to the plan. The result is a more dedicated &amp; self-organized team.</p>
        </li>
        </ul>
        <p className="extra-line">Fill the&nbsp;form on the right to receive you planning poker cards by post. <span>Its 100% free.</span> <span className="fa fa-chevron-right arrow" aria-hidden="true"></span></p>
        </div>
        
       
       
       </span>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>

        <div ref={wr1} className="span4 widget-span widget-type-cell sticky-form">
        <div
         ref={wr}
          className="theiaStickySidebar"
          style={{position:`${top !== 0 ? `fixed`:`relative`}`, transform:top !== 0 ? `translateY(50px)` : null,top:`0px`,maxWidth:`342.3px`,width:`100%`, transition:`all 0.5s`}}>
        <div className="row-fluid-wrapper row-depth-2 row-number-6 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell form-wrap"  >

        <div className="row-fluid-wrapper row-depth-2 row-number-7 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-raw_html " >
        <div className="cell-wrapper layout-widget-wrapper">
        <span id="hs_cos_wrapper_module_1540536915063270"
         className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html">
        <div className="en-text">
        <h4>
        Get your<br/> 
        free poker cards
        </h4>
        <p>Hurry, we have just{' '}
        <strong>{counter}</strong>
        <br/> 
        from the 100 free poker sets left!</p>
        </div>
        <FreePokerCardsForm/>
        </span>
        </div>
        </div>
        </div>
        </div>

        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>

        </div>
        </div>
        </div>

        <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-cell sec-3"  >

        <div className="row-fluid-wrapper row-depth-2 row-number-1 ">
        <div className="row-fluid ">
        <div className="span12 widget-span widget-type-rich_text content"    >
        <div className="cell-wrapper layout-widget-wrapper">
        <span id="hs_cos_wrapper_module_154053883198450" 
        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text">
        <div className="en-text">
        <h5>One set allows you to play with 4 people</h5>
        <p>Each person will have 13 cards - so one set contains 52 cards. Each card is made&nbsp;from a&nbsp;<span>350 gram quality paper&nbsp;- which is actually the same paper we use for our business cards. So they are meant to not break :) Our experience is that after half year they get quite dirty - but this makes them feel even better in your hand!</span></p>
        <img src={cardsmac} alt="cardsmac" title="cardsmac.jpg"/>
        <h5>13 cards for each developer</h5>
        <p>The cards in the deck have numbers on them. A typical planning poker deck has cards showing the Fibonacci sequence including a zero - this is what we prefer as well. We have the following numbers for each developer: 0, 1, 2, 3, 5, 8, 13, 20, 40, 100, ? and ☕ ; The reason for using the Fibonacci sequence is to reflect the inherent uncertainty in estimating larger items.</p>
        <img src={cardsall} alt="cardsall" title="cardsall.jpg" />
        <h5>Put them in a deck</h5>
        <p>The deck is the first thing that will break from our experience. It will last some games - but if i can give you a piece of good advice: keep them together. There is no point of playing a planning poker game when one of the developers is missing a card. We usually drop them in one of our coffee cups - let us know once you've reached&nbsp;that point and we will&nbsp;post you a coffee cup as well ;)</p>
        <img src={cardspack2} alt="cardspack2" title="cardspack2.jpg"/></div>
      </span>
        </div>
        </div>
        </div>
        </div>

        </div>
        </div>
        </div>

        </div>
        </div>
        </div>

        </div>
        </div>
        </div>
        </div>

        </div>
        </div>
        </div>
        
        </div>
    </div>
)
}
export default FreePlanningPokerCards


