import React from 'react'
import FreePlanningPokerCards from '../components/landing-pages/free-planning-poker-cards'
import { graphql, StaticQuery } from 'gatsby'


export default ()=>(<StaticQuery
    query={graphql`
    query PageByIDCus {
      allMarkdownRemark{
        edges {
          node {
            id
            frontmatter {
              counter
              title
            }
          }
        }
      }
    }`}
      render={(data) => <FreePlanningPokerCards data={data}/>}
    />)

