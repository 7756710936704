import React,{useState,useEffect, useRef} from 'react'
import {navigate} from 'gatsby'
import {getLocationData} from '../../location_data'
import { isMobile } from 'react-device-detect'
const FreePokerCardsForm = () =>{
  const windowGlobal = typeof window !== 'undefined' && window  
  const wr3 = useRef(null)
  const [formdata,setFormdata]=useState({})
  const [roleshow,setRoleshow] = useState(false)
  const [update,setUpdate] = useState(false)
  const [selrole,setSelrole] = useState(null)
  const [roledata,setRoledata] = useState([{role:`Company Owner`, selected:false}, 
                                            {role:`Project manager`, selected:false}, 
                                            {role:`Developer`, selected:false}, 
                                            {role:`Designer`, selected:false}
                                            ])
  const handleRole = (e) =>{
      
      if(e === 'open'){
        setRoleshow(true)
      }else if(Number.isInteger(e)){
       let d=[]
       let od = roledata
       od[e].selected = !od[e].selected
       od.forEach(t=>{
           if(t.selected === true){
               d.push(t.role)
           }
       })
       if(d){
       setSelrole(d.join(", "))}
       setRoledata([...od])
  
      }else if(e === 'close'){
            setRoleshow(false) 
        
        
      }
    
  }
  const formonChange =(e)=>{
    setFormdata({...formdata,[e.target.name]:e.target.value})
  }
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }
  const handleFormdata=(e)=>{
      e.preventDefault()
    const userrole = []
    const lifecycledata = []
    let phone = null
    let expert = null
    if(windowGlobal){
        if(windowGlobal.localStorage.getItem('roleData')){
            const t = JSON.parse(windowGlobal.localStorage.getItem('roleData'))
            t.forEach(el=>{
                if(el.checked === true){
                    userrole.push(el.role)
                }
            })
        }
         if(windowGlobal.localStorage.getItem('lifecycledata')){
            const s = JSON.parse(windowGlobal.localStorage.getItem('lifecycledata'))
            s.forEach(el=>{
                if(el.checked === true){
                    lifecycledata.push(el.name)
                }
            })
        }
     
         if(windowGlobal.localStorage.getItem('number')){
          phone = windowGlobal.localStorage.getItem('number')
        }
         if(windowGlobal.localStorage.getItem('expert_name')){
          expert = windowGlobal.localStorage.getItem('expert_name')
        }
    }
 


fetch("/", {
    method: "post",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({"form-name": 'poker_cards_form', 
                    ...formdata,
                  "User_roles":userrole.length !== 0 ? `${userrole.join(", ")}` : null,
                  "Company_lifecycle":lifecycledata.length !== 0 ? `${lifecycledata.join(", ")}` : null,
                  "Phone":phone,
                  "Expertise":expert,
                  "Language":getLocationData('languages') !== null ? getLocationData('languages').split(',')[0] : null,
                  "Country":getLocationData('country_name'),
                  "Zip":getLocationData('zipcode'),
                  "User_country":getLocationData('country_name'),
                  "City":getLocationData('city'),
                  "Latitude":getLocationData('latitude'),
                  "Longitude":getLocationData('longitude'),
                  "State":getLocationData('state_prov'),
                })
  })
    .then(() => {           
        navigate('/thank-you-page')
    })
    .catch(error => alert(error));  
}

useEffect(()=>{
   if(window && !formdata[`email`] && !update){
       if(localStorage.getItem(`email`)){
           const t = localStorage.getItem(`email`)
           setFormdata({...formdata,[`email`]:t})
           setUpdate(true)
       }
   }
   if(!formdata[`companyname`] && windowGlobal.innerWidth > 1023 && !isMobile){
       wr3.current.focus()
   }
},[roleshow,roledata,selrole])
    return (
        <form
        id="sidebar-form"
        method="post"
        name="poker_cards_form"
        netlify-honeypot="bot-field"
        data-netlify="true"
        onSubmit={(e)=>handleFormdata(e)}
        >
        <input type="hidden" name="form-name" value="poker_cards_form" />
            <p hidden>
            <label>
                Don’t fill this out: <input name="bot-field" onChange={formonChange} />
            </label>
            </p>
        <input 
        className="name" 
        type="text" 
        onChange={formonChange} 
        required={true} 
        ref={wr3}
        onFocus={()=>handleRole('close')} 
        name="companyname" 
        placeholder="Company name" />
       
        <span className="select-box-custom">
        <input 
        className="currentrole" 
        required={true}
        type="text" 
        name="selected_role" 
        placeholder="Your role" 
        onChange={formonChange}
        value={selrole}
        onFocus={()=>handleRole('open')}
        
        />
        {roleshow && <div className="closedl" onClick={()=>handleRole('close')}/>}
       {roleshow 
       && 
       <div className="m-select-d-box m-select-d-box_bottom m-select-d-box_hidden">
            <div className="m-select-d-box__search-input-container">
                </div>
                <ul className="m-select-d-box__list-container">
                    {roledata.map((d,i)=>{
                    return <li 
                    className={`m-select-d-box__list-item ${d.selected ? `m-select-d-box__list-item_selected` : ``}`} 
                    key={`role${i}`}
                    onClick={()=>handleRole(i)}
                    >
                        {d.role}
                    </li>
                    })}
                    
                </ul>
            </div>}
        </span>
        <input className="email" onChange={formonChange} required={true} type="email" value={formdata[`email`]} name="email" placeholder="Email"  onFocus={()=>handleRole('close')}/>
        <input className="delivery" onChange={formonChange} required={true} type="text" name="delivery" placeholder="Delivery address"  onFocus={()=>handleRole('close')}/>
        <p hidden>
                    <input name="User_roles" />
                    <input name="Company_lifecycle" />
                    <input name="Phone" />                  
                    <input name="Expertise" />                   
                    <input name="Language" />
                    <input name="Country" />
                    <input name="Zip" />
                    <input name="User_country" />
                    <input name="City" />
                    <input name="Latitude" />
                    <input name="Longitude" />
                    <input name="State" />
        </p>
        <input type="submit" value="Get your cards"  onFocus={()=>handleRole('close')}/>
        </form>
    )
}

export default FreePokerCardsForm;